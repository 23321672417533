import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  searchWorkOrders,
  logout,
  handleMessage,
  handlePusherMsgForRequests,
  getWorkorderTypes,
} from '../../../utils';

import Loading from '../../shared/Loading';
import SearchResult from './SearchResult';
import SearchContext from './SearchContext';

import styles from './styles.module.scss';

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const handleDefaultDateStr = (dayOffset) => {
  const todayMilli = new Date().getTime();
  const modifiedDay = new Date(todayMilli + dayOffset * 86400 * 1000);
  return modifiedDay.toISOString().split('T')[0];
};

const getMilliFromYYYYMMDD = (yyyymmdd) => new Date(yyyymmdd).getTime();

function SearchHome() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [WOId, setWOId] = useState('');
  const [address, setAddress] = useState('');
  const [WOType, setWOType] = useState('');
  const [WOTypes, setWOTypes] = useState([]);
  const [nearby, setNearby] = useState(false);
  const [assignedToMe, setAssignedToMe] = useState(true);
  const [distance, setDistance] = useState(100);
  const [showPending, setShowPending] = useState(true);
  const [resultElement, setResultElement] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enableDateFilter, setEnableDateFilter] = useState(false);
  const [dateFrom, setDateFrom] = useState(handleDefaultDateStr(-61));
  const [dateTo, setDateTo] = useState(handleDefaultDateStr(7));
  const [sortBy, setSortBy] = useState('dtDueDate');
  const [order, setOrder] = useState(-1);

  const { results, setResults } = useContext(SearchContext);

  const clearSearch = () => {
    setCustomerName('');
    setCustomerId('');
    setWOId('');
    setAddress('');
    setWOType('');
    setNearby(false);
    setDistance(100);
    setShowPending(true);
    setResultElement(null);
    setLoading(false);
    handleMessage('', 'SearchHome');
    setResults(null);
  };

  const filterByCriteria = (ele) => {
    if (!ele.vcNextScheduledTime || !enableDateFilter) {
      return true;
    }
    const eleDateStr = ele.vcNextScheduledTime.split(' ')[0]; // YYYY-MM-DD
    const eleDateMilli = getMilliFromYYYYMMDD(eleDateStr);
    return getMilliFromYYYYMMDD(dateFrom) <= eleDateMilli && eleDateMilli <= getMilliFromYYYYMMDD(dateTo);
  };

  const renderWorkorderTypes = () => {
    if (!WOTypes.length) {
      return null;
    }
    const woTypesOptions = WOTypes.map((wo) => <option key={`${wo}-option`} value={wo}>{wo}</option>);
    return (
      <select value={WOType} onChange={(e) => setWOType(e.target.value)} className={styles.WOTypes}>
        <option value="" />
        {woTypesOptions}
      </select>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {};
    let latitude = '';
    let longitude = '';
    if (!navigator.geolocation) {
      handleMessage("Can't get geolocation library", 'SearchHome');
      return;
    }

    const onSuccess = (pos) => {
      // NOTE: it's not as accurate on PC
      const { coords = {} } = pos; // coords: class GeolocationCoordinates
      // hacking the onError function
      // injecting default coordinates when the permission is denied
      latitude = coords.latitude || 38.9652567; // default to Socket office
      longitude = coords.longitude || -92.2969987;

      if (WOId) {
        formData.WorkOrderID = WOId;
      }
      if (customerName) {
        formData.CustomerName = customerName;
      }
      if (customerId) {
        formData.CustomerID = customerId;
      }
      formData.ByAssignment = assignedToMe ? '1' : '0';
      formData.ShowPendingAssigned = showPending ? '1' : '0';
      if (WOType) {
        formData.WorkOrderType = WOType;
      }
      if (nearby) {
        formData.Distance = distance.toString();
        formData.Latitude = latitude;
        formData.Longitude = longitude;
      }
      if (address) {
        formData.Address = address.trim();
      }
      setLoading(true);
      searchWorkOrders(sessionFromReact, formData)
        .then((response) => {
          dispatch(handlePusherMsgForRequests());
          setResults(JSON.parse(response));
          setResultElement(<SearchResult loading={loading} sort={[sortBy, order]} />);
        }).catch((error) => {
          // session related error
          if (error.status === 401) {
            dispatch(logout(error));
          }
        })
        .then(() => { setLoading(false); });
    };

    navigator.geolocation.getCurrentPosition(onSuccess, onSuccess, options);
  };

  const handleDateChange = (val, setter) => {
    setter(val);
  };

  useEffect(() => {
    getWorkorderTypes(sessionFromReact)
      .then((response) => {
        if (response?.length > 0) {
          setWOTypes(JSON.parse(response));
        }
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (results === null) {
      return;
    }
    setResultElement(<SearchResult resultProp={results?.filter(filterByCriteria)} loading={loading} sort={[sortBy, order]} />);
  }, [dateFrom, dateTo, results, enableDateFilter, sortBy, order]);

  return (
    <div className={styles.SearchHome}>
      <button type="button" className={styles.clearSearchBtn} onClick={clearSearch}>Clear search</button>
      <label>
        Enable Date Filter
        <input type="checkbox" value={enableDateFilter} onChange={() => setEnableDateFilter(!enableDateFilter)} />
      </label>
      <label>
        Date From:
        <input type="date" value={dateFrom} disabled={!enableDateFilter} onChange={(e) => handleDateChange(e.target.value, setDateFrom)} />
      </label>
      <label>
        Date To:
        <input type="date" value={dateTo} disabled={!enableDateFilter} onChange={(e) => handleDateChange(e.target.value, setDateTo)} />
      </label>
      <div className={styles.sortByDiv}>
        <h3>Sort by</h3>
        <label>
          <input type="radio" onChange={() => setSortBy('nCount')} checked={sortBy === 'nCount'} />
          Work order id
        </label>
        <label>
          <input type="radio" onChange={() => setSortBy('vcWorkOrderType')} checked={sortBy === 'vcWorkOrderType'} />
          Work order type
        </label>
        <label>
          <input type="radio" onChange={() => setSortBy('vcCustomerName')} checked={sortBy === 'vcCustomerName'} />
          Customer name
        </label>
        <label>
          <input type="radio" onChange={() => setSortBy('vcNextScheduledName')} checked={sortBy === 'vcNextScheduledName'} />
          Event title
        </label>
        <label>
          <input type="radio" onChange={() => setSortBy('vcNextScheduledTime')} checked={sortBy === 'vcNextScheduledTime'} />
          Event time
        </label>
        <label>
          <input type="radio" onChange={() => setSortBy('dtDueDate')} checked={sortBy === 'dtDueDate'} />
          Due Date
        </label>
        <div className={styles.buttonWrapper}>
          Sort By:
          <button type="button" onClick={() => setOrder(-1 * order)}>{order === 1 ? 'Decending' : 'Ascending'}</button>
        </div>
      </div>
      {loading ? <Loading /> : null}
      {resultElement || (
        <form onSubmit={handleSubmit} className={styles.formSearchScreen}>
          <div className="paddingTop10">
            <div className={styles.item}>
              <span className="bold">Customer Name:</span>
              <span>
                <input value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
              </span>
            </div>
          </div>
          <div className={`${styles.item} paddingTop10`}>
            <span className="bold">Customer ID:</span>
            <span>
              <input value={customerId} onChange={(e) => setCustomerId(e.target.value)} placeholder="0000-0000-0000" />
            </span>
          </div>
          <div className={`${styles.item} paddingTop10`}>
            <span className="bold">Work Order ID:</span>
            <span>
              <input value={WOId} onChange={(e) => setWOId(e.target.value)} placeholder="123456" />
            </span>
          </div>
          <div className={`${styles.item} paddingTop10`}>
            <span className="bold">Address:</span>
            <span>
              <input value={address} onChange={(e) => setAddress(e.target.value)} placeholder="2703 Clark Lane, Columbia, MO" />
            </span>
          </div>
          <div className={`${styles.item} paddingTop10`}>
            <span className="bold">Work Order Type:</span>
            <span>
              {renderWorkorderTypes()}
            </span>
          </div>
          <div className={styles.allCheckBox}>
            <div className={`${styles.itemCheckbox} paddingTop10`}>
              <label htmlFor="nearby" className="bold">Nearby:</label>
              <span>
                <input type="checkbox" id="nearby" checked={nearby} onChange={() => setNearby(!nearby)} />
              </span>
            </div>
            <div className={`${styles.itemCheckbox} paddingTop10`}>
              <label htmlFor="Distance" className="bold flex">
                <p>Distance:</p>
                <select
                  id="Distance"
                  value={distance}
                  onChange={(e) => setDistance(e.target.value)}
                  disabled={!nearby}
                >
                  <option value={100}>100</option>
                  <option value={250}>250</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                  <option value={2000}>2000</option>
                </select>
                <p>ft</p>
              </label>
            </div>
            <div className={`${styles.itemCheckbox} paddingTop10`}>
              <label htmlFor="Assigned" className="bold">Assigned to Me:</label>
              <span>
                <input type="checkbox" id="Assigned" checked={assignedToMe} onChange={() => setAssignedToMe(!assignedToMe)} />
              </span>
            </div>
            <div className={`${styles.itemCheckbox} paddingTop10`}>
              <label htmlFor="Pending" className="bold">Show Pending:</label>
              <span>
                <input type="checkbox" id="Pending" checked={showPending} onChange={() => setShowPending(!showPending)} />
              </span>
            </div>
          </div>
          <div className={styles.centerText}>
            <button className={styles.searchButton} type="submit" onClick={handleSubmit}>Search</button>
          </div>
        </form>
      )}
      <div id="msgDivSearchHome" className="error message" />
    </div>
  );
}

export default SearchHome;
